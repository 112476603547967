import wrapRootElement from "./src/reduxWrapper"

import "./src/styles/constants.scss"
import "./src/styles/global.scss"
import "./src/styles/icons.css"

function onPreRouteUpdate({ location, prevLocation }) {}

function onRouteUpdate({ location, prevLocation }) {}

function onClientEntry() {
  // console.log("We've started!", store.getState().App.imBusy)
  // if ('serviceWorker' in navigator) {
  // 		navigator.serviceWorker
  // 			.register('/sw.js')
  // 	}
}

export { wrapRootElement, onRouteUpdate, onPreRouteUpdate, onClientEntry }
