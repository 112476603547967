function getProperShippingsList(shippingsRules = {}, value) {
  if (Object.keys(shippingsRules).length === 0) {
    return []
  }

  const [rangeValue] = Object.keys(shippingsRules).filter(
    (el) => el !== "always" || el !== "free"
  )
  if (Number(rangeValue) < value) {
    return [...shippingsRules[rangeValue], ...shippingsRules["free"]]
  } else {
    return [...shippingsRules["always"], ...shippingsRules["free"]]
  }
}

const actions = {
  addToCart: (state, { payload }) => {
    const action = { ...payload }
    if (state.items.length === 0) {
      let items = [action]
      const val = items.reduce((acc, cur) => acc + cur.value, 0)
      const qty = items.reduce((acc, cur) => acc + cur.quantity, 0)
      // const shippings = getProperShippingsList(state.shippingRules, val)

      return {
        ...state,
        items,
        sum: { val, qty },
        // shippings,
      }
    }

    const { quantity, sale, regularPrice, price } = action

    const productIndex = state.items.findIndex(
      ({ product_id }) => action.product_id === product_id
    )

    if (productIndex === -1) {
      let items = [...state.items, action]
      const val = items.reduce((acc, cur) => acc + cur.value, 0)
      const qty = items.reduce((acc, cur) => acc + cur.quantity, 0)
      // const shippings = getProperShippingsList(state.shippingRules, val)
      return {
        ...state,
        items,
        sum: { val, qty },
        // shippings,
      }
    }

    let items = [...state.items]
    items[productIndex] = {
      ...items[productIndex],
      quantity: quantity + items[productIndex].quantity,
      value:
        items[productIndex].value + sale
          ? quantity * price
          : quantity * regularPrice,
      regularValue: items[productIndex].regularValue + quantity * regularPrice,
    }

    const val = items.reduce((acc, cur) => acc + cur.value, 0)
    const qty = items.reduce((acc, cur) => acc + cur.quantity, 0)

    return {
      ...state,
      items,
      sum: { val, qty },
      // shippings: getProperShippingsList(state.shippingRules, val),
    }
  },

  removeFromCart: (state, { payload }) => {
    const action = { ...payload }
    const { product_id } = action
    let newItems = [...state.items].filter((el) => el.product_id !== product_id)

    const val = newItems.reduce((acc, cur) => acc + cur.value, 0)
    const qty = newItems.reduce((acc, cur) => acc + cur.quantity, 0)

    return {
      ...state,
      items: newItems,
      sum: { val, qty },
      // shippings: getProperShippingsList(state.shippingRules, val),
    }
  },

  changeProductQty: (state, { payload }) => {
    const action = { ...payload }
    const { shippingRules } = state

    const { product_id, quantity } = action
    console.log("change", quantity)
    if (quantity <= 0) {
    } else {
      let newItems = [...state.items].map((el) => {
        if (el.product_id == product_id) {
          el.quantity = quantity
          if (el.sale) {
            el.value = quantity * el.price
            el.regularValue = quantity * el.regularPrice
          } else {
            el.value = quantity * el.regularPrice
            el.regularValue = quantity * el.regularPrice
          }
        }
        return el
      })

      const val = newItems.reduce((acc, cur) => acc + cur.value, 0)
      const qty = newItems.reduce((acc, cur) => acc + cur.quantity, 0)

      return {
        ...state,
        items: newItems,
        sum: { val, qty },
        shippings: getProperShippingsList(state.shippingRules, val),
      }
    }
  },
  setShippings: (state, { payload }) => {
    const action = { ...payload }

    const val = state.items.reduce((acc, cur) => acc + cur.value, 0)

    return {
      ...state,
      shippingRules: action,
      shippings: getProperShippingsList(action, val),
    }
  },
  setShipping: (state, { payload }) => {
    return {
      ...state,
      shipping: { ...payload },
    }
  },

  setCoupon: (state, { payload }) => {
    return {
      ...state,
      coupon: payload,
    }
  },

  setOrderFromApi: (state, { payload }) => {
    return {
      ...state,
      imBusy: false,
      error: null,
      orderInApi: payload,
    }
  },
  onOrderApiError: (state, { payload }) => {
    const action = { ...payload }

    return {
      ...state,
      imBusy: false,
      error: action,
    }
  },
  loadCart: (state, { payload }) => {
    const { cart } = payload
    const { items } = cart
    const val = items.reduce((acc, cur) => acc + cur.value, 0)
    const qty = items.reduce((acc, cur) => acc + cur.quantity, 0)
    return {
      ...state,
      items: items,
      sum: { val, qty },
    }
  },
  resetCart: (state, { payload }) => {
    const action = { ...payload }
    return {
      ...state,
      imBusy: false,
      items: [],
      shippings: getProperShippingsList(state.shippingRules, 0),
      orderInApi: {},
      // shipping: {},
      // coupon: "",
      sum: { val: 0, qty: 0 },
    }
  },

  changePaymentStatus: (state, { payload }) => {
    return {
      ...state,
      payment: payload,
    }
  },
}

function cartReducer(state, action) {
  switch (action.type) {
    case "addToCart":
      return actions.addToCart(state, action)
    case "removeFromCart":
      return actions.removeFromCart(state, action)
    case "changeProductQty":
      return actions.changeProductQty(state, action)
    case "setShippings":
      return actions.setShippings(state, action)
    case "setShipping":
      return actions.setShipping(state, action)

    case "setCoupon":
      return actions.setCoupon(state, action)

    case "loadCart":
      return actions.loadCart(state, action)
    case "resetCart":
      return actions.resetCart(state, action)

    case "setOrderFromApi":
      return actions.setOrderFromApi(state, action)
    case "onOrderApiError":
      return actions.onOrderApiError(state, action)

    case "changePaymentStatus":
      return actions.changePaymentStatus(state, action)
  }
}

export default cartReducer
