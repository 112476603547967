const actions = {
  changeValue(state, { payload }) {
    console.log("state:", state)
    console.log("payload:", payload)
    return {
      ...state,
      ...payload,
    }
  },
  loadCustomer(state, { payload }) {
    const { customer } = payload
    return {
      ...state,
      ...customer,
    }
  },
}

function customerReducer(state, action) {
  switch (action.type) {
    case "changeValue":
      return actions.changeValue(state, action)
    case "loadCustomer":
      return actions.loadCustomer(state, action)
  }
}

export default customerReducer
